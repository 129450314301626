import React, { Component } from 'react';
import { Container, Header } from 'semantic-ui-react';
import '../App.css';

class Contact extends Component {
    render() {
        return (
            <Container style={{ paddingTop: "50px" }} >
                <Header className="header-general header-job" >CREATIONS</Header>
                <br />
                <br />
                <br />
                <br />
            </Container>
        );
    }
}

export default Contact;