import './App.css';
import Page from './components/Page';
import 'semantic-ui-css/semantic.min.css'

function App() {
  return (
    <div style={{ height: "100%" }}>
      <Page/>
    </div>
  );
}

export default App;
